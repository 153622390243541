<template>
  <div id="app">
    <div class="header">
      <img src="@/assets/images/header.png" alt="" />
      <!-- 点击下载下滑到二维码 -->
      <a href="#QR" class="download"
        ><img src="@/assets/images/btn_down.png" alt="" />
      </a>
    </div>
    <Content></Content>
    <div class="footer">
      <p>
        健康游戏忠告:抵制不良游戏,拒绝盗版游戏.注意自我保护,谨防受骗上当.适度游戏益脑,沉迷游戏伤身.合理安排时间,享受健康生活.文明娱乐，远离赌博
      </p>
      <p>冀ICP备2024067012号-1</p>
    </div>
  </div>
</template>

<script>
import Content from "./components/Content.vue";
export default {
  name: "App",
  components: { Content },
  methods: {},
};
</script>

<style lang="scss">
// header
.header {
  height: 2.76rem;
  position: relative;
}
.header img {
  width: 100%;
  height: 100%;
}
.header .download {
  position: absolute;
  width: 1.42rem;
  height: 0.48rem;
  bottom: 0.35rem;
  right: 2rem;
  z-index: 100;
}
.header .download img {
  width: 100%;
  height: 100%;
}
* {
  margin: 0;
  padding: 0;
}
.container {
  width: 100%;
  margin: 0;
  padding: 0;
}
body {
  background-color: #b3b3b2;
}
.footer {
  p {
    margin: 0.2rem auto;
    width: 70%;
    font-size: 10px;
    text-align: center;
    color: #434343;
  }
  padding-bottom: 0.3rem;
}
</style>
