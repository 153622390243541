<template>
  <div class="content">
    <div class="summary">
      <img src="@/assets/images/summary.png" alt="" />
    </div>
    <div class="banner">
      <van-swipe class="my-swipe" :autoplay="3000" indicator-color="#1989fa">
        <van-swipe-item>
          <img src="@/assets/images/content_4.png" alt="" />
        </van-swipe-item>
        <van-swipe-item>
          <img src="@/assets/images/content_3.png" alt="" />
        </van-swipe-item>
        <van-swipe-item>
          <img src="@/assets/images/content_2.png" alt="" />
        </van-swipe-item>
        <van-swipe-item>
          <img src="@/assets/images/content_1.png" alt="" />
        </van-swipe-item>
      </van-swipe>
    </div>
    <div class="notice">
      <img src="@/assets/images/content_notice.png" alt="" />
    </div>
    <div id="QR">
      <div class="and">
        <div class="img"><img src="@/assets/images/1.jpg" alt="" /></div>
        <img
          src="@/assets/images/btn_down_an.png"
          alt=""
          @click="downloadFile()"
        />
      </div>
      <div class="ios">
        <div class="img"><img src="@/assets/images/1.jpg" alt="" /></div>
        <img
          src="@/assets/images/btn_down_ios.png"
          alt=""
          @click="downloadFile()"
        />
      </div>
    </div>
  </div>
</template>

<script>
export default {
  // eslint-disable-next-line vue/multi-word-component-names
  name: "content",
  data() {
    return {};
  },
  methods: {
    downloadFile() {
      // 模拟一个文件的数据
      const fileData = "Hello, world!";

      // 创建一个Blob对象，该对象表示一个文件的数据
      const blob = new Blob([fileData], { type: "text/plain" });

      // 创建一个URL对象，用于指向Blob对象
      const url = URL.createObjectURL(blob);

      // 创建一个<a>标签元素
      const link = document.createElement("a");
      link.href = url;
      link.download = "休闲娱乐.txt"; // 设置下载文件的文件名

      // 模拟用户点击下载链接
      link.click();

      // 释放URL对象
      URL.revokeObjectURL(url);
      console.log("下载完成");
    },
  },
};
</script>

<style lang="scss">
.content {
  padding: 0.5rem 0;
  top: -0.2rem;
  position: relative;
  z-index: 10;
  width: 95%;

  background-color: #fff;
  margin: 0 auto;
  border-radius: 0.2rem;
  border-top: 1px solid transparent;
}

.content .summary {
  width: 4.78rem;
  height: 1.25rem;
  margin: 0.4rem auto 0.2rem;
}
.banner {
  width: 95%;
  margin: 0 auto;
  height: 4.18rem;
  img {
    width: 100%;
    height: 97%;
  }
  .notice {
    width: 95%;
    margin: 0 auto;
    height: 3.62rem;
    margin-top: 0.67rem;
  }
}
#QR {
  margin-top: 0.86rem;
  display: flex;
  justify-content: space-around;
  img {
    width: 1.78rem;
    height: 0.6rem;
    margin-top: 0.33rem;
  }
  .and,
  .ios {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  .img {
    width: 2.66rem;
    height: 2.66rem;
    img {
      height: 100%;
      width: 100%;
    }
    margin-bottom: 0.33rem;
  }
}
</style>
